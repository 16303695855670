import { Box } from '@mui/material';
import { RedesignedTable } from '../../RedesignedTable';
import { columns } from './columns';

export const ProtocolTable = (props: any) => {
  const { elements, additionalElements, type } = props;

  return (
    <Box>
      <RedesignedTable
        items={elements}
        columns={columns(type, additionalElements)}
        paginated={false}
        wrapped={false}
      />
    </Box>
  );
};
