import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { ColumnsType } from 'components/Tables/BaseTable/types';
import { Link } from 'react-router-dom';
import { makeUrl } from 'routes/makeUrl';
import { formatNumber, formatUsd } from 'shared/utils/formatter';
import { SeeMoreButton } from '../BaseTable/SeeMoreButton';
import { routesMapping } from 'routes/mappings';
import { UserRole } from 'shared/utils/enum/UserEnum';
import { Actions } from '../Actions';
import { colors } from 'shared/constants/theme';

export const getColumns = ({
  handleEdit,
  onRowDelete,
  onAdd,
}: any): ColumnsType[] => [
  {
    id: 'actions',
    label: 'Actions',
    minWidth: 130,
    align: 'left',
    sortable: false,
    render: (row) => (
      <Actions
        row={row}
        handleEdit={handleEdit}
        onRowDelete={onRowDelete}
        onAdd={onAdd}
      />
    ),
  },
  {
    id: 'customer_details',
    label: 'Customer details',
    sortable: false,
    minWidth: 170,
    align: 'left',
    render: ({ id: customerId, role }) =>
      role === UserRole.CUSTOMER ? (
        <SeeMoreButton
          route={makeUrl(routesMapping.adminUser, { customerId })}
        />
      ) : (
        <></>
      ),
  },
  {
    id: 'companyName',
    label: 'Company name',
    expandableIcon: true,
    sortable: false,
    minWidth: 200,
    align: 'left',
  },
  {
    id: 'totalInvestment',
    label: 'Total investments',
    minWidth: 100,
    align: 'left',
    render: ({ totalInvestment }) => `$${formatUsd(totalInvestment)}`,
  },
  {
    id: 'view',
    label: 'Customer view',
    minWidth: 100,
    align: 'left',
    sortable: false,
    render: ({ id: customerId, role }: any) =>
      role === 'Customer' ? (
        <Link
          to={{
            pathname: makeUrl(routesMapping.adminViewCustomerHome, {
              customerId,
            }),
          }}
          target="_blank"
        >
          <VisibilityOutlinedIcon
            fontSize="small"
            sx={{
              color: colors.lightGreen,
              ':hover': {
                cursor: 'pointer',
              },
            }}
          />
        </Link>
      ) : (
        '-'
      ),
  },
];

export const getExpandColumns = ({
  handleEdit,
  onRowDelete,
}: any): ColumnsType[] => [
  {
    id: 'username',
    label: 'Username',
    minWidth: 200,
    align: 'left',
  },
  {
    id: 'email',
    label: 'Email Address',
    minWidth: 200,
    align: 'left',
  },
  {
    id: 'actions',
    label: 'Actions',
    minWidth: 200,
    align: 'left',
    render: (row) => (
      <Actions row={row} handleEdit={handleEdit} onRowDelete={onRowDelete} />
    ),
  },
];
