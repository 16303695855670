import { Box } from '@mui/material';
import { formatNumberToLocale } from 'shared/utils/formatter';
import { ColumnsType } from '../BaseTable/types';
import { Actions } from '../Actions';

export const getColumns = ({ handleEdit, onRowDelete }: any): ColumnsType[] => [
  {
    id: 'name',
    label: 'Opportunity name',
    minWidth: 100,
    align: 'left',
  },
  {
    id: 'username',
    label: 'Supplier',
    minWidth: 100,
    align: 'left',
    render: (hosting: any) => {
      return <Box>{hosting.username}</Box>;
    },
  },
  {
    id: 'location',
    label: 'Location',
    minWidth: 100,
    align: 'left',
  },
  {
    id: 'electricityCost',
    label: 'Electricity cost, $ / KWh',
    minWidth: 100,
    align: 'left',
    render: (hosting: any) => {
      return <Box>${formatNumberToLocale(hosting.electricityCost, 4)}</Box>;
    },
  },
  {
    id: 'priority',
    label: 'Priority',
    minWidth: 100,
    align: 'left',
    render: (hosting: any) => {
      return <Box>{hosting.priority ? '✅' : '-'}</Box>;
    },
  },
  {
    id: 'actions',
    label: 'Actions',
    minWidth: 130,
    align: 'left',
    sortable: false,
    render: (row) => (
      <Actions row={row} handleEdit={handleEdit} onRowDelete={onRowDelete} />
    ),
  },
];

export const getSubTableColumns = ({
  handleEdit,
  onRowDelete,
}: any): ColumnsType[] => [
  {
    id: 'device',
    label: 'DEVICE NAME',
    minWidth: 100,
    align: 'left',
    sortable: false,
    render: (props: any) => {
      return props?.hardware?.device;
    },
  },
  {
    id: 'coin',
    label: 'COIN',
    minWidth: 100,
    align: 'left',
    sortable: false,
    render: (props: any) => {
      return props?.hardware?.currency;
    },
  },
  {
    id: 'supplier',
    label: 'SUPPLIER',
    minWidth: 100,
    align: 'left',
    sortable: false,
    render: (props: any) => {
      return props.supplier.username;
    },
  },
  {
    id: 'hashrate',
    label: 'HASHRATE',
    minWidth: 100,
    align: 'left',
    sortable: false,
    render: (props: any) => {
      return props?.hardware?.hashrate;
    },
  },
  {
    id: 'electricity',
    label: 'POWER, WATT',
    minWidth: 100,
    sortable: false,
    align: 'left',
    render: (props: any) => {
      return props.hardware?.electricity;
    },
  },
  {
    id: 'totalPrice',
    label: 'TOTAL PRICE, $',
    minWidth: 100,
    sortable: false,
    align: 'left',
    render: (props: any) => {
      return `$${formatNumberToLocale(props.totalPrice, 2)}`;
    },
  },
  {
    id: 'actions',
    label: 'Actions',
    minWidth: 130,
    align: 'left',
    sortable: false,
    render: (row) => (
      <Actions row={row} handleEdit={handleEdit} onRowDelete={onRowDelete} />
    ),
  },
];
