import { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { CustomModal } from 'components/Modals/CustomModal';
import { DeleteModal } from 'components/Modals/DeleteModal';
import { BaseTable } from 'components/Tables/BaseTable';
import { getColumns } from './columns';
import { useNotification } from 'shared/hooks/useNotification';
import EventsService from 'shared/services/events.service';
import { useParams } from 'react-router-dom';
import { EventForm } from 'components/Forms/EventForm';
import { RedesignedTable } from '../RedesignedTable';

export const AdminEventsTable = ({ currency }: any) => {
  const [events, setEvents] = useState<Record<string, any> | null>(null);
  const [delEvent, setDelEvent] = useState<boolean>(false);
  const [eventData, setEventData] = useState<Record<string, any>>([]);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [isEventAdded, setEventAdded] = useState<boolean>(false);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const { id, customerId } = useParams();

  const { showSnackbar } = useNotification();

  const fetchEvents = async () => {
    setEvents(null);
    try {
      const data = await EventsService.getUserEvents(id! || customerId, {
        pageNumber: currentPage,
        limit: rowsPerPage,
        currency: currency,
      });
      const { events, totalPages } = data;
      setEvents(events);
      setTotalPages(totalPages);
    } catch (err) {
      console.log(err);
    }
  };

  const handleDeleteRoi = async (id: string) => {
    setDelEvent(true);
    setEventData({ id });
  };

  const removeROI = async () => {
    await EventsService.deleteEvent(eventData.id);
    fetchEvents();
    setDelEvent(false);
    showSnackbar('Successfully Deleted', 'success');
  };

  const handleEdit = (event: any) => {
    setShowEditModal(true);
    setEventData(event);
  };

  const handleCountItems = (event: number) => {
    setRowsPerPage(event);
    setCurrentPage(1);
  };

  const columns = getColumns({
    handleEdit,
    onRowDelete: handleDeleteRoi,
  });

  useEffect(() => {
    fetchEvents();
  }, []);

  return (
    <>
      <RedesignedTable
        tableName="Events"
        items={events}
        columns={columns}
        setShowModal={setEventAdded}
        totalPages={totalPages}
        rowsPerPage={rowsPerPage}
        setCurrentPage={setCurrentPage}
        setRowsPerPage={handleCountItems}
        currentPage={currentPage}
        searchable={false}
      />
      <CustomModal open={showEditModal} onClose={() => setShowEditModal(false)}>
        <EventForm
          setShowModal={setShowEditModal}
          editData={eventData}
          onFinish={fetchEvents}
          currency={currency}
        />
      </CustomModal>
      <CustomModal open={!!delEvent} onClose={() => setDelEvent(false)}>
        <Box className="container">
          <DeleteModal
            title="Event"
            onClick={removeROI}
            onCancel={() => setDelEvent(false)}
          />
        </Box>
      </CustomModal>
      <CustomModal open={isEventAdded} onClose={() => setEventAdded(false)}>
        <EventForm
          setShowModal={setEventAdded}
          onFinish={fetchEvents}
          currency={currency}
        />
      </CustomModal>
    </>
  );
};
