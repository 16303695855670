import {
  Box,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { ColumnsType } from 'components/Tables/BaseTable/types';
import { RedesignedTableRow } from '../RedesignedTableRow/RedesignedTableRow';
import { StyleExpandedTableCell } from '../../styles';

type Props = {
  open: boolean;
  columns?: ColumnsType[];
  items: any[];
  parentRow: Record<string, any>;
};

export const ExpandableRow = ({ open, columns, items, parentRow }: Props) => {
  return (
    <TableRow sx={{ backgroundColor: 'transparent !important' }}>
      <TableCell style={{ padding: 0, width: '100%' }} colSpan={100}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box sx={{ margin: 1 }}>
            {parentRow.rowChildren ? (
              parentRow.rowChildren
            ) : (
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    {columns
                      ? columns.map((column: any) => (
                          <StyleExpandedTableCell
                            key={column.id}
                            style={{
                              minWidth: column.minWidth,
                              fontSize: '14px',
                              fontWeight: '600',
                            }}
                            align={column.align}
                          >
                            {column.renderLabel
                              ? column.renderLabel(parentRow)
                              : column.label}
                          </StyleExpandedTableCell>
                        ))
                      : null}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {items?.length &&
                    items?.map((row: Record<string, any>) => (
                      <RedesignedTableRow
                        key={row.id}
                        row={row}
                        columns={columns}
                      />
                    ))}
                </TableBody>
              </Table>
            )}
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  );
};
