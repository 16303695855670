import { Actions } from '../Actions';
import { ColumnsType } from '../BaseTable/types';

export const getColumns = ({ handleEdit, onRowDelete }: any): ColumnsType[] => [
  {
    id: 'actions',
    label: 'Actions',
    minWidth: 130,
    align: 'left',
    sortable: false,
    render: (row) => (
      <Actions row={row} handleEdit={handleEdit} onRowDelete={onRowDelete} />
    ),
  },
  {
    id: 'device',
    label: 'Device name',
    minWidth: 100,
    sortable: false,
    align: 'left',
  },
  {
    id: 'coin',
    label: 'Coin',
    minWidth: 100,
    sortable: false,
    align: 'left',
    render: (props: any) => props.currency,
  },
  {
    id: 'hashrate',
    label: 'Hashrate, TH/s',
    minWidth: 100,
    sortable: false,
    align: 'left',
  },
  {
    id: 'electricity',
    label: 'Power, Watt',
    minWidth: 100,
    sortable: false,
    align: 'left',
  },
];
