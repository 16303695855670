import { Box } from '@mui/material';
import { SectionHeading } from 'components/SectionHeading';
import { RenderField } from 'pages/UserData';
import { fieldsStyle } from 'pages/UserData/styles';
import { colors } from 'shared/constants/theme';

type Props = {
  contactPerson?: string;
  email?: string;
  signedBy?: string;
};

export const Details = ({ contactPerson, email, signedBy }: Props) => {
  return (
    <Box>
      <SectionHeading
        sx={{
          marginBottom: '32px',
        }}
        name="Customer details"
      />
      <Box
        sx={{ backgroundColor: 'white', borderRadius: '20px', padding: '20px' }}
      >
        <Box
          sx={{
            borderRadius: 5,
            overflow: 'hidden',
            maxWidth: '1000',
            border: '1px solid #E0E0E0',
          }}
        >
          <Box sx={{ ...fieldsStyle, borderRadius: '0px', width: '100%' }}>
            {RenderField('Contact person', contactPerson)}
          </Box>
          <Box
            sx={{
              ...fieldsStyle,
              backgroundColor: colors.tableGrey,
              width: '100%',
            }}
          >
            {RenderField('Email', email)}
          </Box>
          <Box sx={{ ...fieldsStyle, borderRadius: '0px', width: '100%' }}>
            {RenderField('Signed by', signedBy)}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
