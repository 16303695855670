import { Grid } from '@mui/material';
import { SectionHeading } from 'components/SectionHeading';
import { ContractsTable } from 'components/Tables/ContractsTable';

export const Contracts = (props: any) => {
  const { contracts, currencyValue } = props;
  return (
    <Grid>
      <ContractsTable contracts={contracts} currencyValue={currencyValue} />
    </Grid>
  );
};
