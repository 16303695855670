import { Box, Collapse, Table, TableCell, TableRow } from '@mui/material';
import { RedesignedTable } from 'components/Tables/RedesignedTable';
import { useState } from 'react';
import { ExpandArrow } from './rowItems';
import { TableActions } from 'components/TableActions';

export const ExpandableRow = (props: any) => {
  const {
    row,
    columns,
    color,
    showEnd,
    nestingKey,
    rowColumns,
    handleEdit,
    onRowDelete,
    onSubRowDelete,
    handleSubTableEdit,
    subTableHeaderOption,
    subTableAction,
  } = props;
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow
        key={row.id}
        sx={{
          border: 'none !important',
          '&:hover': {
            '& .MuiButton-contained': {
              backgroundColor: `${color} !important`,
              borderColor: `${color} !important`,
              color: 'white',
            },
          },
          '& .MuiButton-contained': {
            backgroundColor: 'transparent',
            color: '#828282',
            border: '1px solid #828282',
            boxShadow: 'none',
          },
        }}
      >
        {columns.map((column: any, index: number) => {
          const value = row[column.id];
          return (
            <TableCell key={column.id} align="left">
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {index === 0 ? (
                  <ExpandArrow open={open} setOpen={setOpen} />
                ) : null}
                {column.render
                  ? column.render(row)
                  : value !== undefined || value !== null
                    ? value
                    : '-'}
              </Box>
            </TableCell>
          );
        })}
        {showEnd && (
          <TableActions
            handleEdit={handleEdit}
            row={row}
            onRowDelete={onRowDelete}
          />
        )}
      </TableRow>
      {open && (
        <TableRow
          sx={{ padding: '0 !important', margin: 0, border: 'none !important' }}
        >
          <TableCell sx={{ padding: '0 !important', margin: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Table size="small" aria-label="purchases">
                <RedesignedTable
                  items={row[nestingKey]}
                  columns={rowColumns}
                  searchable={false}
                  paginated={false}
                  handleEdit={handleSubTableEdit}
                  onRowDelete={onSubRowDelete}
                  showEnd={showEnd}
                  wrapped={false}
                  addButton={false}
                  headerOption={subTableHeaderOption}
                  headerAction={subTableAction}
                  rowData={row}
                />
              </Table>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};
