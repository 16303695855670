import { Link } from 'react-router-dom';
import { makeUrl } from 'routes/makeUrl';
import { routesMapping } from 'routes/mappings';
import { ColumnsType } from 'components/Tables/BaseTable/types';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { Actions } from '../Actions';
import { colors } from 'shared/constants/theme';

export const getColumns = ({ handleEdit, onRowDelete }: any): ColumnsType[] => [
  {
    id: 'actions',
    label: 'Actions',
    minWidth: 130,
    align: 'left',
    sortable: false,
    render: (row) => (
      <Actions row={row} handleEdit={handleEdit} onRowDelete={onRowDelete} />
    ),
  },
  {
    id: 'email',
    label: 'Email',
    minWidth: 100,
    align: 'left',
  },
  {
    id: 'companyName',
    label: 'Company',
    minWidth: 100,
    align: 'left',
  },
  {
    id: 'username',
    label: 'Username',
    minWidth: 100,
    align: 'left',
  },
  {
    id: 'affiliatePercent',
    label: 'Affiliate Percent',
    minWidth: 100,
    align: 'left',
  },
  {
    id: 'view',
    label: 'View',
    minWidth: 100,
    align: 'left',
    sortable: false,
    render: ({ id, role }: any) =>
      role === 'Affiliate' ? (
        <Link
          to={{
            pathname: makeUrl(routesMapping.adminViewAffiliateHome, {
              affiliateId: id,
            }),
          }}
          target="_blank"
        >
          <VisibilityOutlinedIcon
            fontSize="small"
            sx={{
              color: colors.lightGreen,
              ':hover': {
                cursor: 'pointer',
              },
            }}
          />
        </Link>
      ) : (
        '-'
      ),
  },
];
