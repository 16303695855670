import { Box } from '@mui/material';
import editIcon from 'assets/images/editIcon.svg';
import removeIcon from 'assets/images/removeIcon.svg';
import addUser from 'assets/images/addUser.svg';

type Props = {
  handleEdit?: (value: any) => void;
  onRowDelete?: (value: string) => void;
  onAdd?: (value: any) => void;
  row: any;
  readOnly?: boolean;
};

export const Actions = ({
  handleEdit,
  onRowDelete,
  onAdd,
  row,
  readOnly = false,
}: Props) => {
  return (
    <>
      {handleEdit && (
        <Box
          onClick={() => !readOnly && handleEdit(row)}
          component="img"
          sx={{
            cursor: 'pointer',
            marginRight: '10px',
            height: 50,
            width: 50,
            maxHeight: { xs: 25, md: 25 },
            maxWidth: { xs: 25, md: 25 },
            opacity: readOnly ? 0.5 : 1,
          }}
          src={editIcon}
        />
      )}
      {onRowDelete && (
        <Box
          onClick={() => !readOnly && onRowDelete(row.id)}
          component="img"
          sx={{
            cursor: 'pointer',
            marginRight: '10px',
            height: 50,
            width: 50,
            maxHeight: { xs: 25, md: 25 },
            maxWidth: { xs: 25, md: 25 },
            opacity: readOnly ? 0.5 : 1,
          }}
          src={removeIcon}
        />
      )}
      {onAdd && (
        <Box
          onClick={() => !readOnly && onAdd(row)}
          component="img"
          sx={{
            color: 'red',
            cursor: 'pointer',
            height: 50,
            width: 50,
            maxHeight: { xs: 25, md: 25 },
            maxWidth: { xs: 25, md: 25 },
            opacity: readOnly ? 0.5 : 1,
          }}
          src={addUser}
        />
      )}
    </>
  );
};
