import { useState, useEffect } from 'react';
import moment from 'moment';
import { colors } from 'shared/constants/theme';
import { Typography } from '@mui/material';

export const LiveUtcDateTime = () => {
  const [utcTime, setUtcTime] = useState(
    moment().utc().format('MMM D, HH:mm [UTC]'),
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setUtcTime(moment().utc().format('MMM D, HH:mm [UTC]'));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return <Typography color={colors.mediumGrey}>{utcTime}</Typography>;
};
