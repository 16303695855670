import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Activity } from 'components/CustomerDetails/Activity';
import { Layout } from 'components/Layout';
import { Chain } from './Chain';
import { Performance } from './Performance';
import { Operations } from 'pages/Customer/Operation/Operations';
import { Miners } from './Miners';
import { Contracts } from './Contracts';
import { Filter } from 'components/Filter';
import { KeyboardArrowLeft } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import { SectionHeading } from 'components/SectionHeading';
import { makeUrl } from 'routes/makeUrl';
import { routesMapping } from 'routes/mappings';
import { handleCoinAbb } from 'shared/utils/currency';
import ChainService from 'shared/services/chain.service';
import DashboardService from 'shared/services/dashboard.service';
import Transaction from '../Transaction';
import { activeTabStyles, tabStyles, tabsContainer } from './styles';
import { useNotification } from 'shared/hooks/useNotification';
import { colors } from 'shared/constants/theme';
import { WalletStatus } from '../Transaction/WalletStatus';
import bitcoinService from 'shared/services/bitcoin.service';

export const Operation = () => {
  const [dashboardData, setDashboardData] = useState<any>(null);
  const [chainStatistics, setChainStatistics] = useState<any>();
  const [contractData, setContractData] = useState<any>([]);
  const [filterData, setFilterData] = useState<any>({
    contracts: [],
    machineTypes: [],
  });
  const [hashrateDataArray, setHashrateDataArray] = useState<any>(null);
  const [statistics, setStatistics] = useState<any>(null);
  const [roiChart, setRoiChart] = useState<any>(null);
  const [roiStatistics, setRoiStatistics] = useState<any>(null);
  const [activeTab, setActiveTab] = useState('operations');
  const [walletData, setWalletData] = useState<any>(null);
  const [walletTransactions, setWalletTransactions] = useState<any>(null);

  const { customerId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { showSnackbar } = useNotification();

  const resetDashboardData = () => {
    setDashboardData(null);
    setStatistics(null);
    setHashrateDataArray(null);
    setRoiChart(null);
    setRoiStatistics(null);
  };

  const getFilteredData: (contractIds?: string[]) => Promise<void> = async (
    contractIds,
  ) => {
    setContractData(contractIds);
    resetDashboardData();
    // await fetchDashboardData(contractIds)
    // await getDashboardStatistics(contractIds)
    // await getRoi(contractIds)
    await Promise.all([
      fetchDashboardData(contractIds),
      getDashboardStatistics(contractIds),
      getRoi(contractIds),
    ]);
  };

  const getDashboardFilterData = async () => {
    try {
      const data = await DashboardService.getDashboardFilterData({
        customerId,
        currency: handleCurrencyValue(true),
      });
      return setFilterData(data);
    } catch (err: any) {}
  };

  const getChartStatistics = async () => {
    try {
      const params = {
        currency: handleCurrencyValue(true),
      };
      const data = await ChainService.getChainStatistics(params);

      return setChainStatistics(data);
    } catch (err: any) {}
  };

  const fetchDashboardData: (contractIds?: string[]) => Promise<void> = async (
    contractIds,
  ) => {
    try {
      const params = {
        contractIds,
        customerId,
        currency: handleCurrencyValue(true),
      };

      const { revenues, ...rest } =
        await DashboardService.getDashboardChartsData(params);

      setDashboardData(rest);

      const { hashrateData } =
        await DashboardService.getDashboardHashrateChart(params);

      setHashrateDataArray(hashrateData);
    } catch (err: any) {
      showSnackbar(err.error, 'error');
    }
  };

  const getRoi: (contractIds?: string[]) => Promise<void> = async (
    contractIds,
  ) => {
    try {
      const { chart, ...roiStatistics } = await DashboardService.getRoi({
        customerId,
        contractIds,
        currency: handleCurrencyValue(true),
      });

      setRoiChart(chart);
      return setRoiStatistics(roiStatistics);
    } catch (err: any) {
      showSnackbar(err.error, 'error');
    }
  };

  const getDashboardStatistics: (
    contractIds?: string[],
  ) => Promise<void> = async (contractIds) => {
    const params = {
      contractIds,
      customerId,
      currency: handleCurrencyValue(true),
    };
    try {
      const data = await DashboardService.getDashboardStatistics(params);
      return setStatistics(data);
    } catch (err: any) {
      showSnackbar(err.error, 'error');
    }
  };

  const getWalletData = async () => {
    try {
      const { transactions, ...data } = await bitcoinService.getWalletData({
        customerId,
        currency: handleCurrencyValue(true),
      });

      setWalletTransactions(transactions);
      return setWalletData(data);
    } catch (err: any) {
      return showSnackbar(err.error, 'error');
    }
  };

  useEffect(() => {
    fetchDashboardData();
    getDashboardFilterData();
    getDashboardStatistics();
    getChartStatistics();
    getWalletData();
    getRoi();
  }, []);

  const subAccountUserIds = useMemo(
    () => filterData.contracts.map((contract: any) => contract.name).sort(),
    [filterData.contracts],
  );

  const handleBackNavigate = () => {
    const { adminViewCustomerHome, customerHome, affiliateViewCustomerHome } =
      routesMapping;

    const path = location.pathname.includes('admin')
      ? adminViewCustomerHome
      : location.pathname.includes('affiliate')
        ? affiliateViewCustomerHome
        : customerHome;

    const url = makeUrl(path, { customerId });
    navigate(url);
  };

  const handleCurrencyValue = (item: boolean = false) => {
    const currency = location.search.split('=')[1];

    if (item) {
      return currency;
    }

    if (currency === 'BitcoinCash') {
      return 'Bitcoin Cash';
    }

    return currency;
  };

  return (
    <Layout
      backgroundChildren={
        <>
          <Box
            sx={{
              display: 'flex',
              gap: '20px',
              alignItems: 'center',
              marginBottom: '20px',
            }}
          >
            <Button
              sx={{
                borderColor: colors.lightGrey,
                borderRadius: 2,
                padding: '5px',
                minWidth: 'unset',
              }}
              variant="outlined"
              onClick={() => handleBackNavigate()}
            >
              <KeyboardArrowLeft />
            </Button>
            <SectionHeading
              sx={{
                color: 'white',
              }}
              name={`${handleCurrencyValue()} Dashboard`}
            />
          </Box>
          <Box sx={tabsContainer}>
            <Box
              sx={activeTab === 'operations' ? activeTabStyles : tabStyles}
              onClick={() => setActiveTab('operations')}
            >
              Your Operations
            </Box>
            <Box
              sx={activeTab === 'transactions' ? activeTabStyles : tabStyles}
              onClick={() => setActiveTab('transactions')}
            >
              Transactions
            </Box>
          </Box>
          {activeTab === 'transactions' ? (
            <WalletStatus
              wallet={walletData}
              transactions={walletTransactions}
              currency={handleCurrencyValue()}
              coinAbb={handleCoinAbb(handleCurrencyValue())}
            />
          ) : (
            <Box>
              <Operations statistics={statistics} />
              <Chain
                data={chainStatistics}
                currency={handleCurrencyValue()}
                currencyValue={handleCurrencyValue(true)}
                coinAbb={handleCoinAbb(handleCurrencyValue())}
              />
            </Box>
          )}
        </>
      }
    >
      {activeTab === 'transactions' ? (
        <Transaction
          currency={handleCurrencyValue()}
          currencyValue={handleCurrencyValue(true)}
        />
      ) : (
        <Box>
          <Filter
            contracts={subAccountUserIds}
            onFilter={getFilteredData}
            sectionName="Your activity"
          />
          <Activity
            hashrateData={hashrateDataArray}
            currencyValue={handleCurrencyValue(true)}
          />
          <Performance statistics={roiStatistics} chart={roiChart} />
          <Contracts
            contracts={contractData}
            currencyValue={handleCurrencyValue(true)}
          />
          <Miners
            worldData={dashboardData ? dashboardData.machineByLocation : []}
            distributionData={
              dashboardData ? dashboardData.machineByLocation : []
            }
            machinePerTypeData={
              dashboardData ? dashboardData.machineByMachineType : []
            }
          />
        </Box>
      )}
    </Layout>
  );
};
