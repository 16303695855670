import { Actions } from '../Actions';
import { ColumnsType } from '../BaseTable/types';

export const getColumns = ({ handleEdit, onRowDelete }: any): ColumnsType[] => [
  {
    id: 'actions',
    label: 'Actions',
    minWidth: 130,
    align: 'left',
    sortable: false,
    render: (row) => (
      <Actions row={row} handleEdit={handleEdit} onRowDelete={onRowDelete} />
    ),
  },
  {
    id: 'email',
    label: 'Email',
    minWidth: 100,
    align: 'left',
  },
  {
    id: 'owner',
    sortable: false,
    label: 'Owner',
    minWidth: 100,
    align: 'left',
    render: ({ owner: { email } }: any) => email,
  },
  {
    id: 'companyName',
    label: 'Company',
    minWidth: 100,
    align: 'left',
  },
  {
    id: 'username',
    label: 'Username',
    minWidth: 100,
    align: 'left',
  },
];
