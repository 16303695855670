import * as yup from 'yup';

type AdminSupplierFormSchema = { password: boolean };
export const getAdminSupplierFormSchema = ({
  password,
}: AdminSupplierFormSchema) =>
  yup.object().shape({
    email: yup.string().required('Email is a required field').email(),
    phoneNumber: yup.string().notRequired(),
    username: yup.string().required('Username is a required field'),
    isHardware: yup
      .bool()
      .test(
        '',
        'Please, specify supplier type(s)',
        (_, { from }) =>
          from && (from[0].value.isHardware || from[0].value.isHosting),
      ),
    isHosting: yup
      .boolean()
      .test(
        '',
        'Please, specify supplier type(s)',
        (_, { from }) =>
          from && (from[0].value.isHardware || from[0].value.isHosting),
      ),
    password: password
      ? yup
          .string()
          .test(
            '',
            'Password is a required field',
            (pass, { from }) =>
              from &&
              (!from[0].value.isHosting || (from[0].value.isHosting && pass)),
          )
          .test(
            '',
            'Password must be at least 8 characters',
            (pass) => !!pass && pass?.length > 7,
          )
      : yup.string(),
  });
