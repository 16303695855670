import { ColumnsType } from 'components/Tables/BaseTable/types';
import { Actions } from '../Actions';

export const getColumns = ({
  handleEdit,
  onRowDelete,
  readOnly,
}: any): ColumnsType[] => [
  {
    id: 'actions',
    label: 'Actions',
    minWidth: 130,
    align: 'left',
    sortable: false,
    render: (row) => (
      <Actions
        row={row}
        handleEdit={handleEdit}
        onRowDelete={onRowDelete}
        readOnly={readOnly}
      />
    ),
  },
  {
    id: 'username',
    label: 'Full Name',
    minWidth: 100,
  },
  {
    id: 'email',
    label: 'Email Address',
    minWidth: 100,
  },
  {
    id: 'affiliatePercent',
    label: 'Upfront %',
    minWidth: 100,
    render: ({ affiliatePercent }) => `${affiliatePercent}%`,
  },
  {
    id: 'hashratePercent',
    label: 'Hashrate %',
    minWidth: 100,
    render: ({ hashratePercent }) => `${hashratePercent}%`,
  },
];
