import { useState } from 'react';
import { Box, Dialog, Pagination, Typography } from '@mui/material';
import { Document } from 'react-pdf';
import { Progress } from 'components/Progress';
import FilesService from 'shared/services/files.service';
import { downloadFile } from 'shared/utils/fileDownloader';
import { Page } from './styles';
import { CloudDownloadOutlined, VisibilityOutlined } from '@mui/icons-material';
import { colors } from 'shared/constants/theme';

export const PdfActions = (props: any) => {
  const { pdfUrl } = props;

  const [showPdf, setShowPdf] = useState(false);
  const [numPages, setNumPages] = useState<number>();
  const [currentPage, setCurrentPage] = useState<number>(1);

  const onDocumentLoadSuccess = ({ numPages: nextNumPages }: any): void => {
    setNumPages(nextNumPages);
  };

  const fileName = FilesService.getFileUrl(pdfUrl);

  return (
    <>
      <Box display="flex" justifyContent="center" alignItems="center" gap={2}>
        {pdfUrl ? (
          <>
            <VisibilityOutlined
              onClick={() => setShowPdf((prevValue) => !prevValue)}
              fontSize="small"
              sx={{
                color: colors.lightGreen,
                ':hover': {
                  cursor: 'pointer',
                },
              }}
            />

            <CloudDownloadOutlined
              onClick={() => downloadFile(pdfUrl)}
              fontSize="small"
              sx={{
                color: colors.lightGreen,
                ':hover': {
                  cursor: 'pointer',
                },
              }}
            />
          </>
        ) : (
          <Typography>File Missing</Typography>
        )}
      </Box>
      <Dialog
        PaperProps={{
          sx: {
            padding: '20px',
          },
        }}
        open={showPdf}
        onClose={() => setShowPdf(false)}
      >
        <Document
          loading={
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Progress />
            </Box>
          }
          onLoadSuccess={onDocumentLoadSuccess}
          file={fileName}
        >
          <Page
            renderTextLayer={false}
            renderAnnotationLayer={false}
            key={`page_${currentPage}`}
            className="pdf-page"
            pageNumber={currentPage}
          />
        </Document>
        {numPages && (
          <Pagination
            onChange={(_, value) => setCurrentPage(value)}
            count={numPages}
          />
        )}
      </Dialog>
    </>
  );
};
