import { colors } from 'shared/constants/theme';

export const cardStyles = {
  borderRadius: 5,
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  background: `${colors.lightGrey}`,
};
