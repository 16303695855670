import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/system';
import { CustomModal } from 'components/Modals/CustomModal';
import { DeleteModal } from 'components/Modals/DeleteModal';
import { BaseTable } from 'components/Tables/BaseTable';
import { getColumns } from './columns';
import { Layout } from 'components/Layout';
import MachinesService from 'shared/services/machines.service';
import { useNotification } from 'shared/hooks/useNotification';
import moment from 'moment';
import { machineStatus } from 'pages/ContractData/columns';
import { EventLogForm } from 'components/Forms/EventLogForm';
import './index.css';
import { RedesignedTable } from 'components/Tables/RedesignedTable';

const Machines = (): JSX.Element => {
  const { id } = useParams();

  const [machine, setMachine] = useState<Record<string, any>>([]);
  const [eventLogs, setEventLogs] = useState<Record<string, any> | null>(null);
  const [delEventLog, setDelEventLog] = useState<boolean>(false);
  const [eventLogData, setEventLogData] = useState<Record<string, any>>([]);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [isEventLogAdded, setEventLogAdded] = useState<boolean>(false);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const { showSnackbar } = useNotification();

  const fetchMachine = async () => {
    try {
      const machine = await MachinesService.getMachine(id!);
      setMachine(machine);
    } catch (err) {}
  };

  useEffect(() => {
    if (!id) return;
    fetchMachine();
  }, [id]);

  const fetchEventLogs = async () => {
    setEventLogs(null);
    try {
      const data = await MachinesService.getMachineEventLogs(id!, {
        pageNumber: currentPage,
        limit: rowsPerPage,
      });
      const { eventLogs, totalPages } = data;
      setEventLogs(eventLogs);
      setTotalPages(totalPages);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchEventLogs();
  }, [id, rowsPerPage, currentPage]);

  const handleDeleteEventLog = async (eventLogId: string) => {
    setDelEventLog(true);
    setEventLogData({ eventLogId });
  };

  const removeMachine = async () => {
    await MachinesService.deleteMachineEventLog(eventLogData.eventLogId);
    fetchEventLogs();
    setDelEventLog(false);
    showSnackbar('Successfully Deleted', 'success');
  };

  const handleEdit = (machine: any) => {
    setShowEditModal(true);
    setEventLogData(machine);
  };

  const handleCountItems = (event: number) => {
    setRowsPerPage(event);
    setCurrentPage(1);
  };

  const renderField = (label: string, value: string | number): JSX.Element => {
    return (
      <div
        style={{
          display: 'flex',
          columnGap: 20,
        }}
      >
        <div
          className="titles"
          style={{
            minWidth: 250,
          }}
        >
          {label}
        </div>
        <div className="values">{value}</div>
      </div>
    );
  };

  const columns = getColumns({
    handleEdit,
    onRowDelete: handleDeleteEventLog,
  });

  const fieldsStyle = {
    display: 'flex',
    alignItems: 'center',
    columnGap: 300,
    padding: '20px',
  };

  const fieldsStyleGreen = {
    display: 'flex',
    columnGap: 300,
    height: 50,
    alignItems: 'center',
    background: '#f7faf8',
    padding: '20px',
  };

  return (
    <Layout
      backgroundChildren={
        <div className="contact">
          <div
            className="infoContract"
            style={{
              maxWidth: 1000,
              borderRadius: 10,
              boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.2)',
            }}
          >
            <div style={fieldsStyle}>
              <div
                style={{
                  maxWidth: 200,
                }}
              >
                {renderField('ID', machine.id)}
              </div>
              <div>{renderField('Serial Number', machine.serialNumber)}</div>
            </div>
            <div style={fieldsStyleGreen}>
              <div
                style={{
                  maxWidth: 200,
                }}
              >
                {renderField(
                  'Plug Date',
                  moment(machine.plugDate).format('MM/DD/yyyy'),
                )}
              </div>
              <div>{renderField('Status', machineStatus[machine.status])}</div>
            </div>
          </div>
        </div>
      }
    >
      <CustomModal
        open={isEventLogAdded}
        onClose={() => setEventLogAdded(false)}
      >
        <EventLogForm
          setShowModal={setEventLogAdded}
          onFinish={fetchEventLogs}
        />
      </CustomModal>
      <Box className="ContractData">
        <RedesignedTable
          tableName="Event Logs"
          items={eventLogs}
          columns={columns}
          setShowModal={setEventLogAdded}
          totalPages={totalPages}
          rowsPerPage={rowsPerPage}
          setCurrentPage={setCurrentPage}
          setRowsPerPage={handleCountItems}
          currentPage={currentPage}
        />
        <CustomModal
          open={showEditModal}
          onClose={() => setShowEditModal(false)}
        >
          <EventLogForm
            setShowModal={setShowEditModal}
            editData={eventLogData}
            onFinish={fetchEventLogs}
          />
        </CustomModal>
        <CustomModal open={delEventLog} onClose={() => setDelEventLog(false)}>
          <Box className="container">
            <DeleteModal
              title="machine"
              onClick={removeMachine}
              onCancel={() => setDelEventLog(false)}
            />
          </Box>
        </CustomModal>
      </Box>
    </Layout>
  );
};

export default Machines;
