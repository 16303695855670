import { AddCircleOutline } from '@mui/icons-material';
import { Box } from '@mui/material';
import { useLocation, useParams } from 'react-router-dom';
import AffiliateSubAccountForm from 'components/Forms/UserForms/AffiliateSubAccountForm';
import { CustomModal } from 'components/Modals/CustomModal';
import { DeleteModal } from 'components/Modals/DeleteModal';
import { SectionHeading } from 'components/SectionHeading';
import { RedesignedTable } from 'components/Tables/RedesignedTable';
import { useCallback, useState } from 'react';
import { useAuth } from 'shared/hooks/useAuth';
import { useFetch } from 'shared/hooks/useFetch';
import { useNotification } from 'shared/hooks/useNotification';
import UsersService from 'shared/services/users.service';
import { getColumns } from 'components/Tables/AffiliateSubAccountTable/columns';
import { colors } from 'shared/constants/theme';

const AffiliateSubAccountTable = () => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [limit, setLimit] = useState<number>(5);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [userData, setUserData] = useState();
  const [delUserEmail, setDelUserEmail] = useState<string>('');

  const { user } = useAuth();
  const { affiliateId } = useParams();
  const location = useLocation();
  const { showSnackbar } = useNotification();

  const handleCountItems = (event: number) => {
    setLimit(event);
  };

  const [{ users, totalPages }, updateUsers] = useFetch(
    () =>
      UsersService.getAffiliateSubAccounts({
        limit,
        pageNumber,
        customerId: affiliateId,
      }),
    [limit, pageNumber],
  );

  const handleEdit = (user: any): void => {
    setShowEditModal(true);
    setUserData(user);
  };

  const handleDeleteUser = useCallback(async () => {
    if (delUserEmail) {
      await UsersService.deleteAffiliateSubAcc(delUserEmail);
      updateUsers();
      setDelUserEmail('');
      showSnackbar('Successfully Deleted', 'success');
    }
  }, [delUserEmail]);

  const isAffiliateView = !!location.pathname.includes('affiliate-view');

  const columns = getColumns({
    handleEdit,
    onRowDelete: setDelUserEmail,
    readOnly: isAffiliateView,
  });

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          rowGap: '60px',
          marginTop: '20px',
          justifyContent: 'space-between',
        }}
      >
        <SectionHeading
          sx={{
            color: 'white',
            marginBottom: '32px',
          }}
          name="Sub accounts"
        />

        <Box display="flex" columnGap={2}>
          <Box
            sx={{
              height: '40px',
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              backgroundColor: '#ffffff',
              border: `1px solid ${colors.lightGreen}`,
              borderRadius: '20px !important',
              color: colors.black,
              padding: '5px 15px',
              opacity: isAffiliateView ? 0.5 : 1,
            }}
            className="addAdminStyles"
            onClick={() => !isAffiliateView && setShowEditModal(true)}
          >
            <AddCircleOutline
              sx={{
                cursor: 'pointer',
                marginRight: '10px',
                height: 20,
                width: 20,
                borderRadius: 20,
              }}
            />
            Add
          </Box>
        </Box>
      </Box>
      <RedesignedTable
        items={users}
        columns={columns}
        totalPages={totalPages}
        rowsPerPage={limit}
        setRowsPerPage={handleCountItems}
        currentPage={pageNumber}
        actionsColor={colors.white}
        setCurrentPage={setPageNumber}
        readOnlyWithActions={isAffiliateView}
      />

      <CustomModal open={showEditModal} onClose={() => setShowEditModal(false)}>
        <AffiliateSubAccountForm
          setShowModal={setShowEditModal}
          onFinish={updateUsers}
          users={users}
          editData={userData}
        />
      </CustomModal>

      <CustomModal open={!!delUserEmail} onClose={() => setDelUserEmail('')}>
        <Box className="container">
          <DeleteModal
            title="user"
            onClick={handleDeleteUser}
            onCancel={() => setDelUserEmail('')}
          />
        </Box>
      </CustomModal>
    </>
  );
};

export default AffiliateSubAccountTable;
