import { Box, Typography } from '@mui/material';
import { ColumnsType } from 'components/Tables/BaseTable/types';
import { makeUrl } from 'routes/makeUrl';
import { routesMapping } from 'routes/mappings';
import { UserRole } from 'shared/utils/enum/UserEnum';
import { getCoinInfo } from 'shared/utils/getCoinInfo';
import { SeeMoreButton } from '../BaseTable/SeeMoreButton';
import countryCodes from 'shared/constants/country-codes.json';
import { MachineName } from '../ContractsTable/cells';
import { colors } from 'shared/constants/theme';
import { Actions } from '../Actions';

const contractDetailsUrls: Partial<Record<UserRole, (id: string) => string>> = {
  [UserRole.ADMIN]: (id) => makeUrl(routesMapping.adminContract, { id }),
  [UserRole.AFFILIATE]: (id) =>
    makeUrl(routesMapping.affiliateContract, { id }),
  [UserRole.AFFILIATE_SUBACCOUNT]: (id) =>
    makeUrl(routesMapping.affiliateContract, { id }),
};

export const getColumns = (
  role: UserRole,
  { handleEdit, onRowDelete }: any,
): ColumnsType[] => {
  const columns: ColumnsType[] = [
    {
      label: 'Details',
      sortable: false,
      minWidth: 200,
      align: 'left',
      render: ({ id }) => (
        <SeeMoreButton route={contractDetailsUrls[role]!(id)} />
      ),
    },
    {
      id: 'name',
      label: 'Name',
      minWidth: 120,
      align: 'left',
    },
    {
      id: 'subAccountUserId',
      label: 'Sub Account User Id',
      minWidth: 120,
      align: 'left',
    },
    {
      id: 'customerEmail',
      label: 'Customer Email',
      minWidth: 100,
      align: 'left',
    },
    {
      id: 'coin',
      label: 'Currency',
      minWidth: 170,
      align: 'left',
      sortable: false,
      expandableIcon: true,
      render: (row) => {
        const coin = getCoinInfo(row.currency);
        return (
          <Box
            sx={{ display: 'flex', alignItems: 'center', columnGap: '10px' }}
          >
            <Box>
              <img
                src={coin.logo}
                alt={coin.coin}
                style={{ width: '40px', height: '40px' }}
              />
            </Box>
            <Box>
              <Typography
                sx={{
                  fontWeight: 600,
                }}
              >
                {coin.coinName}
              </Typography>
              <Typography
                sx={{ fontWeight: 300, color: colors.grey, fontSize: '12px' }}
              >
                {coin.coinAbb}
              </Typography>
            </Box>
          </Box>
        );
      },
    },
    {
      id: 'machineType',
      label: 'Machine Type',
      minWidth: 150,
      align: 'left',
      render: ({ machineType, machineModel }) => (
        <MachineName machineType={machineType} machineModel={machineModel} />
      ),
    },
    {
      id: 'numberOfMachines',
      label: 'Number Of Machines',
      minWidth: 100,
      align: 'left',
    },
    {
      id: 'machineWatt',
      label: 'Unit Power (watt)',
      minWidth: 100,
      align: 'left',
      render: ({ machineWatt }) =>
        `${Number(machineWatt).toLocaleString('en-US')}`,
    },
    {
      id: 'machineTH',
      label: 'Unit Hashrate (TH)',
      minWidth: 100,
      align: 'left',
    },
    {
      id: 'electricityCost',
      label: 'Electricity & Hosting (KWh)',
      minWidth: 100,
      align: 'left',
      render: ({ electricityCost }) =>
        `${Number(electricityCost).toLocaleString('en-US')}$`,
    },
    {
      id: 'minersCost',
      label: 'Cost Of Miners Installed',
      minWidth: 100,
      align: 'left',
      render: ({ minersCost }) =>
        `${Number(minersCost).toLocaleString('en-US')} $`,
    },
    {
      id: 'monthlyElectricityCost',
      label: 'Monthly Electricity Cost (+/- 5%)',
      minWidth: 180,
      align: 'left',
      render: ({ monthlyElectricityCost }) =>
        `${
          isNaN(monthlyElectricityCost)
            ? 0
            : Number(monthlyElectricityCost).toLocaleString('en-US')
        }$`,
    },
    {
      id: 'hostingCost',
      label: 'Cost Of Hosting Upfront',
      minWidth: 100,
      align: 'left',
      render: ({ hostingCost }) =>
        `${
          isNaN(hostingCost) ? 0 : Number(hostingCost).toLocaleString('en-US')
        }$`,
    },
    {
      id: 'location',
      label: 'Location',
      minWidth: 100,
      align: 'left',
      render: ({ location }) =>
        countryCodes.countries.find((country) => country.alpha2 === location)
          ?.country,
    },
    {
      id: 'hostingCompany',
      label: 'Hosting Company',
      minWidth: 100,
      align: 'left',
    },
    {
      id: 'machineSupplier',
      label: 'Hosting Provider',
      minWidth: 100,
      align: 'left',
      render: ({ machineSupplier }) => machineSupplier?.username ?? 'N/A',
    },
    {
      id: 'totalInvestment',
      label: 'Hardware Investment',
      minWidth: 100,
      align: 'left',
      render: ({ totalInvestment }) =>
        `${Number(totalInvestment).toLocaleString('en-US')} $`,
    },
    {
      id: 'hearstUpfront',
      label: 'Hearst Upfront',
      minWidth: 100,
      align: 'left',
      render: ({ hearstUpfront }) =>
        `${Number(hearstUpfront).toLocaleString('en-US')} $`,
    },
    {
      id: 'affiliateUpfront',
      label: 'Affiliate Upfront',
      minWidth: 100,
      align: 'left',
      render: ({ affiliateUpfront }) => {
        if (!affiliateUpfront) return '-';
        return `${Number(affiliateUpfront).toLocaleString('en-US')} $`;
      },
    },
    {
      id: 'hostingDepositFees',
      label: 'Hosting Deposit Fees',
      minWidth: 100,
      align: 'left',
      render: ({ hostingDepositFees }) =>
        `${Number(hostingDepositFees).toLocaleString('en-US')} $`,
    },
    {
      id: 'affiliatePercent',
      label: 'Affiliate Hashrate %',
      minWidth: 100,
      align: 'left',
      render: ({ affiliatePercent }) => {
        if (!affiliatePercent) return '-';
        return `${Number(affiliatePercent).toLocaleString('en-US')}%`;
      },
    },
    {
      id: 'yearToCapitalConstitution',
      label: 'Year To Capital Constitution',
      minWidth: 100,
      align: 'left',
    },
    {
      id: 'subAccountApiKey',
      label: 'Sub Account Api Key',
      minWidth: 100,
      align: 'left',
    },
    {
      id: 'subAccountApiSecret',
      label: 'Sub Account Api Secret',
      minWidth: 100,
      align: 'left',
    },
    {
      id: 'contractStartingDate',
      label: 'Contract Starting Date',
      minWidth: 100,
      align: 'left',
    },
    {
      id: 'timeToPlug',
      label: 'Time To Plug',
      minWidth: 100,
      align: 'left',
    },
    {
      id: 'plugDate',
      label: 'Plug Date',
      minWidth: 100,
      align: 'left',
    },
    {
      id: 'contractStatus',
      label: 'Contract Status',
      minWidth: 130,
      align: 'left',
    },
  ];

  if (role === UserRole.ADMIN) {
    columns.unshift({
      id: 'actions',
      label: 'Actions',
      minWidth: 130,
      align: 'left',
      sortable: false,
      render: (row) => (
        <Actions row={row} handleEdit={handleEdit} onRowDelete={onRowDelete} />
      ),
    });
  }

  return columns;
};
