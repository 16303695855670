import { AddCircleOutline, ErrorOutline } from '@mui/icons-material';
import { SectionHeading } from 'components/SectionHeading';
import { RedesignedTableHead } from './components/RedesignedTableHead/RedesignedTableHead';
import { RedesignedTablePagination } from './components/RedesignedTablePagination/RedesignedTablePagination';
import { RedesignedTableRow } from './components/RedesignedTableRow/RedesignedTableRow';
import { useEffect, useMemo, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import { Box, SelectChangeEvent, Tooltip, Typography } from '@mui/material';
import { Progress } from 'components/Progress';
import { PropsTypes } from './types';
import { SearchInput } from 'components/SearchInput';
import { getComparator, stableSort } from './sorting';
import { colors } from 'shared/constants/theme';
import './index.css';

export const RedesignedTable = ({
  tableName,
  items,
  columns,
  sectionTitleColor,
  totalPages,
  currentPage,
  setCurrentPage,
  rowsPerPage,
  setRowsPerPage,
  search,
  setSearch,
  searchable,
  info,
  paginated = true,
  expandColumns,
  expandPropertyName,
  wrapped = true,
  isEditable = true,
  searchBeforeActions,
  handleEdit,
  onRowDelete,
  showEnd,
  onAdd,
  addButtonText,
  addButton = true,
  actionsColor = colors.black,
  readOnlyWithActions = false,
  actions,
  setShowModal,
  headerOption,
  headerAction,
  rowData,
}: PropsTypes) => {
  const [isLoading, setIsLoading] = useState(false);
  const pageNumbers =
    totalPages ?? Math.ceil(items?.length / (rowsPerPage ?? 0));

  const handleChange = (event?: SelectChangeEvent) => {
    if (event) setRowsPerPage?.(event.target.value as unknown as number);
  };

  const onSearchChange = async (value: string) => {
    if (setSearch) setSearch(value);
  };

  const [order, setOrder] = useState<any>('asc');
  const [orderBy, setOrderBy] = useState<any>('');

  useEffect(() => {
    if (!items) setIsLoading(true);
    else setIsLoading(false);
  }, [search, items]);

  useEffect(() => {
    if (
      currentPage &&
      setCurrentPage &&
      pageNumbers < currentPage &&
      pageNumbers !== 0
    ) {
      setCurrentPage(pageNumbers);
    }
  }, [pageNumbers, currentPage]);

  const visibleRows = useMemo(
    () =>
      items && items.length > 0
        ? stableSort(items, getComparator(order, orderBy))
        : [],
    [items, order, orderBy],
  );

  return (
    <Box>
      <Box className={wrapped ? 'tableWrapper' : ''}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            marginBottom: '24px',
            width: '100%',
            flexDirection: { xs: 'column', sm: 'row', md: 'row', lg: 'row' },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: '20px',
              width: '100%',
            }}
          >
            {tableName && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                  alignItems: {
                    xs: 'fles-start',
                    sm: 'center',
                    md: 'center',
                    lg: 'center',
                  },
                  flexDirection: {
                    xs: 'column',
                    sm: 'row',
                    md: 'row',
                    lg: 'row',
                  },
                }}
              >
                <SectionHeading
                  sx={{
                    color: sectionTitleColor || colors.black,
                  }}
                  name={tableName}
                />
                <Box
                  display="flex"
                  columnGap={2}
                  sx={{
                    flexDirection: { xs: 'column', sm: 'row' },
                    rowGap: { xs: '10px', sm: '0px' },
                  }}
                >
                  {searchable && searchBeforeActions && (
                    <Box sx={{ borderRadius: '0px' }}>
                      <SearchInput value={search} setValue={onSearchChange} />
                    </Box>
                  )}
                  {actions}
                  {addButton && (
                    <Box
                      sx={{
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        backgroundColor: 'transparent',
                        border: `1px solid ${colors.lightGreen}`,
                        borderRadius: '20px !important',
                        color: actionsColor,
                        padding: '5px 15px',
                        height: 40,
                        width: 'fit-content',
                        opacity: readOnlyWithActions ? 0.5 : 1,
                      }}
                      className="addAdminStyles"
                      onClick={() =>
                        !readOnlyWithActions &&
                        setShowModal &&
                        setShowModal(true)
                      }
                    >
                      <AddCircleOutline
                        sx={{
                          cursor: 'pointer',
                          marginRight: '10px',
                          height: 20,
                          color: actionsColor,
                          width: 20,
                          borderRadius: 20,
                        }}
                      />
                      <Typography sx={{ color: actionsColor }}>
                        {addButtonText ? addButtonText : 'Add'}
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Box>
            )}
            {searchable && !searchBeforeActions && (
              <Box sx={{ borderRadius: '0px' }}>
                <SearchInput value={search} setValue={onSearchChange} />
              </Box>
            )}
          </Box>
          {info && (
            <Tooltip title={info} arrow enterTouchDelay={0}>
              <ErrorOutline
                sx={{
                  color: '#828282',
                }}
              />
            </Tooltip>
          )}
          {onAdd && (
            <Box>
              <Box
                sx={{
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  backgroundColor: '#ffffff',
                  border: `1px solid ${colors.lightGreen}`,
                  borderRadius: '20px !important',
                  color: colors.black,
                  padding: '5px 15px',
                }}
                className="addAdminStyles"
                onClick={onAdd}
              >
                <AddCircleOutline
                  sx={{
                    cursor: 'pointer',
                    marginRight: '10px',
                    height: 20,
                    width: 20,
                    borderRadius: 20,
                  }}
                />
                {'Add'}
              </Box>
            </Box>
          )}
        </Box>
        <Paper
          sx={{
            backgroundColor: colors.lightGrey,
            color: '#131613',
            borderRadius: '20px',
            boxShadow: 'none',
          }}
        >
          <Box
            sx={{
              width: '100%',
              borderRadius: '0px',
              marginBottom: 0,
            }}
          >
            <TableContainer
              sx={{
                marginBottom: 0,
                padding: 0,
                border: 'none',
              }}
            >
              <Table
                stickyHeader
                className="redesignedTable"
                sx={{
                  border: '1px solid #eaeaea',
                  borderRadius: '16px',
                  borderSpacing: '0 !important',
                }}
              >
                <RedesignedTableHead
                  columns={columns}
                  order={order}
                  orderBy={orderBy}
                  setOrder={setOrder}
                  setOrderBy={setOrderBy}
                  headerOption={headerOption}
                  headerAction={headerAction}
                  rowData={rowData}
                />
                <TableBody sx={{ height: 70 }}>
                  {isLoading ? (
                    <Progress className="center" size={24} />
                  ) : !items?.length ? (
                    <Box className="center">EMPTY CONTENT</Box>
                  ) : (
                    items?.length &&
                    visibleRows?.map(
                      (row: Record<string, any>, index: number) => (
                        <RedesignedTableRow
                          readOnlyWithActions={readOnlyWithActions}
                          index={index}
                          key={row.id}
                          row={row}
                          columns={columns}
                          expandColumns={expandColumns}
                          expandPropertyName={expandPropertyName}
                          onRowDelete={onRowDelete}
                          handleEdit={handleEdit}
                          rowData={rowData}
                          isEditable={isEditable}
                          showEnd={showEnd}
                        />
                      ),
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {paginated ? (
              <RedesignedTablePagination
                currentPage={currentPage}
                handleChange={handleChange}
                totalPages={totalPages}
                rowsPerPage={rowsPerPage}
                setCurrentPage={setCurrentPage}
                pageNumbers={pageNumbers}
                totalItems={items?.length}
              />
            ) : (
              <></>
            )}
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};
