import { useCallback, useEffect, useState } from 'react';
import { AdminForm } from 'components/Forms/UserForms/AdminForm';
import { CustomModal } from 'components/Modals/CustomModal';
import { Box } from '@mui/material';
import { DeleteModal } from 'components/Modals/DeleteModal';
import { getColumns } from './columns';
import { colors } from 'shared/constants/theme';
import { UserType } from 'shared/types/UserType';
import UsersService from 'shared/services/users.service';
import { useNotification } from 'shared/hooks/useNotification';
import { UserRole } from 'shared/utils/enum/UserEnum';
import { RedesignedTable } from '../RedesignedTable';
import { useDebounce } from 'shared/hooks/useDebounce';

export default function AdminsTable(props: any) {
  const { setUserDeleted } = props;
  const [search, setSearch] = useState<string>('');
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [users, setUsers] = useState<UserType[] | null>(null);
  const [userData, setUserData] = useState<any[]>([]);
  const [delUserEmail, setDelUserEmail] = useState<string>('');
  const [totalPages, setTotalPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const { showSnackbar } = useNotification();

  const debouncedSearch = useDebounce(search, 500);

  const fetchUsers = async () => {
    setUsers(null);
    try {
      const response = await UsersService.getUsers({
        pageNumber: currentPage,
        limit: rowsPerPage,
        q: debouncedSearch,
        role: UserRole.ADMIN,
      });
      const { users, totalPages } = response;
      setTotalPages(totalPages);
      setUsers(users);
    } catch (err: any) {
      console.error(err);
    }
  };

  const handleEdit = (user: any): void => {
    setShowEditModal(true);
    setUserData(user);
  };

  const handleDeleteUser = useCallback(async () => {
    if (delUserEmail) {
      await UsersService.deleteUser(delUserEmail);
      fetchUsers();
      setDelUserEmail('');
      setUserDeleted(true);
      showSnackbar('Successfully Deleted', 'success');
    }
  }, [delUserEmail]);

  const handleCountItems = (event: number) => {
    setRowsPerPage(event);
    setCurrentPage(1);
  };

  const columns = getColumns({
    handleEdit,
    onRowDelete: setDelUserEmail,
  });

  useEffect(() => {
    fetchUsers();
  }, [debouncedSearch, rowsPerPage, currentPage]);

  return (
    <>
      <CustomModal open={showModal} onClose={() => setShowModal(false)}>
        <AdminForm setShowModal={setShowModal} onFinish={fetchUsers} />
      </CustomModal>
      <RedesignedTable
        tableName="Admin Dashboard"
        items={users}
        search={search}
        setSearch={setSearch}
        sectionTitleColor={'#FFF'}
        columns={columns}
        setShowModal={setShowModal}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        totalPages={totalPages}
        rowsPerPage={rowsPerPage}
        actionsColor={colors.white}
        setRowsPerPage={handleCountItems}
        searchable={true}
      />
      <CustomModal open={showEditModal} onClose={() => setShowEditModal(false)}>
        <AdminForm
          setShowModal={setShowEditModal}
          editData={userData}
          onFinish={fetchUsers}
        />
      </CustomModal>
      <CustomModal open={!!delUserEmail} onClose={() => setDelUserEmail('')}>
        <Box className="container">
          <DeleteModal
            title="user"
            onClick={handleDeleteUser}
            onCancel={() => setDelUserEmail('')}
          />
        </Box>
      </CustomModal>
    </>
  );
}
