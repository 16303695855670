import { Box } from '@mui/material';
import logo from 'assets/images/login.png';
import { Header } from 'components/Layout/Header';
import { useAuth } from 'shared/hooks/useAuth';
import { Footer } from './Footer';
import { HearstLogo } from 'components/Icons/HearstLogo';
import { useMemo } from 'react';
import { colors } from 'shared/constants/theme';

export const Layout = (props: any) => {
  const { children, backgroundChildren } = props;
  const { user } = useAuth();

  const containerStyles = useMemo(() => {
    const initialStyles = {
      background:
        'linear-gradient(117deg, rgba(4,40,20,1) 0%, rgba(0,0,0,1) 30%, rgba(0,0,0,1) 70%, rgba(4,40,20,1) 100%)',
      borderBottomLeftRadius: '20px',
      borderBottomRightRadius: '20px',
      borderTopLeftRadius: '0px',
      borderTopRightRadius: '0px',
      padding: {
        sm: 3,
        xs: 3,
        md: '60px 32px 32px',
        lg: '60px 32px 32px',
        xl: '60px 32px 32px',
      },
    };

    return { ...initialStyles };
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        minHeight: '100vh',
        flexDirection: 'column',
        justifyContent: 'space-between',
        backgroundColor: colors.backgroundGrey,
      }}
    >
      <Box>
        {user && <Header />}
        {backgroundChildren && (
          <Box sx={{ ...containerStyles }}>{backgroundChildren}</Box>
        )}
        <Box
          sx={{
            padding: {
              sm: 3,
              xs: 3,
              md: '40px 32px',
              lg: '40px 32px',
              xl: '40px 32px',
            },
            paddingX: 5,
          }}
        >
          {children}
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};

export const AuthLayout = (props: any) => {
  const { children } = props;
  return (
    <Box
      className="layoutContainer"
      sx={{
        bgcolor: 'black',
        display: 'flex',
        justifyContent: 'space-between',
        gap: '80px',
        overflow: 'hidden',
        minWidth: '100%',
        minHeight: '100%',
        overflowY: 'hidden',
        borderRadius: '0px',
        padding: '85px 70px',
      }}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          rowGap: 10,
          // alignItems: 'center',
        }}
      >
        <HearstLogo height={'80px'} width={'140px'} />
        {children}
      </Box>
      <Box className="login-left-side">
        <img
          className="imgLogin login-image"
          src={logo}
          alt="login welcome back"
        />
      </Box>
    </Box>
  );
};
