import { Upfront } from 'components/CustomerDetails/types';
import { useParams } from 'react-router';
import { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { Layout } from 'components/Layout';
import { Balances } from 'pages/Affiliate/AffiliateOperation/Balances';
import { EarnedBtc } from 'components/CustomerDetails/EarnedBTC';
import { useNotification } from 'shared/hooks/useNotification';
import BitcoinService from 'shared/services/bitcoin.service';
import contractsService from 'shared/services/contracts.service';
import { coins } from 'shared/utils/currency';
import {
  activeTabStyles,
  logoContainer,
  logoStyles,
  tabsContainer,
  tabStyles,
} from 'components/CustomerDetails/styles';
import { SectionHeading } from 'components/SectionHeading';
import { colors } from 'shared/constants/theme';

export const AffiliateOperation = () => {
  const [walletData, setWalletData] = useState<any>(null);
  const [upfront, setUpfront] = useState<Upfront>();
  const [selectedCurrency, setSelectedCurrency] = useState(coins[0]);

  const { affiliateId } = useParams();
  const { showSnackbar } = useNotification();

  const getWalletData = async () => {
    setWalletData(null);
    try {
      const { transactions, ...data } = await BitcoinService.getWalletData({
        currency: selectedCurrency.currency,
        customerId: affiliateId,
      });

      return setWalletData(data);
    } catch (err: any) {
      return showSnackbar(err.error, 'error');
    }
  };

  const getTotalUpfront = async () => {
    setUpfront(undefined);
    try {
      const affiliateUpfront = await contractsService.getAffiliateUpfront({
        currency: selectedCurrency.currency,
        customerId: affiliateId,
      });

      setUpfront(affiliateUpfront);
    } catch (err: any) {
      showSnackbar(err.error, 'error');
    }
  };

  useEffect(() => {
    getWalletData();
    getTotalUpfront();
  }, [selectedCurrency]);

  return (
    <Layout
      backgroundChildren={
        <>
          <SectionHeading
            sx={{
              color: '#fff',
            }}
            name="Balances"
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: '60px',
              marginTop: '20px',
            }}
          >
            <Box sx={tabsContainer}>
              {coins.map((coin: any) => (
                <Box
                  key={coin.currency}
                  sx={
                    selectedCurrency.currency === coin.currency
                      ? activeTabStyles
                      : upfront && walletData
                        ? tabStyles
                        : { ...tabStyles, opacity: 0.5 }
                  }
                  onClick={() =>
                    upfront && walletData && setSelectedCurrency(coin)
                  }
                >
                  <Box sx={logoContainer}>
                    <img
                      src={coin.logo}
                      alt={coin.coinName}
                      style={logoStyles}
                    />
                  </Box>
                  <Typography
                    sx={{
                      color:
                        selectedCurrency.currency === coin.currency
                          ? colors.black
                          : colors.white,
                      fontSize: {
                        xs: '10px',
                        sm: '10px',
                        md: '14px',
                        lg: '14px',
                        xl: '14px',
                      },
                    }}
                  >
                    {coin.coinAbb}
                  </Typography>
                </Box>
              ))}
            </Box>
            <Balances
              walletData={walletData}
              btcSinceBeginning={upfront?.btcSinceBeginning}
              selectedCurrency={selectedCurrency}
            />
          </Box>
        </>
      }
    >
      <EarnedBtc
        statistics={upfront}
        coinAbb={selectedCurrency.coinAbb}
        isAffiliate={true}
      />
    </Layout>
  );
};
