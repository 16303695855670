import { Actions } from 'components/Tables/Actions';
import { ColumnsType } from 'components/Tables/BaseTable/types';

export const machineStatus: any = {
  Active: 'Active',
  Unplugged: 'Unplugged',
  Under_Maintenance: 'Under Maintenance',
};

export const getColumns = ({
  showActions,
  handleEdit,
  onRowDelete,
}: any): ColumnsType[] => {
  const columns: ColumnsType[] = [
    {
      id: 'serialNumber',
      label: 'Serial Number',
      minWidth: 100,
      align: 'left',
      sortable: false,
    },
    {
      id: 'status',
      label: 'Status',
      minWidth: 100,
      align: 'left',
      sortable: false,
      render: ({ status }: any) => `${machineStatus[status]}`,
    },
    {
      id: 'plugDate',
      label: 'Plugged Date',
      minWidth: 100,
      sortable: false,
      align: 'left',
    },
  ];

  if (showActions) {
    columns.unshift({
      id: 'actions',
      label: 'Actions',
      minWidth: 130,
      align: 'left',
      sortable: false,
      render: (row) => (
        <Actions row={row} handleEdit={handleEdit} onRowDelete={onRowDelete} />
      ),
    });
  }
  return columns;
};
