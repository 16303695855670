import { useEffect, useState } from 'react';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { IFormInput } from 'components/Forms/InvoiceForm/types';
import { columns } from './columns';
import { useNotification } from 'shared/hooks/useNotification';
import InvoicesService from 'shared/services/invoices.service';
import { RedesignedTable } from 'components/Tables/RedesignedTable';
import {
  Box,
  Checkbox,
  FormControl,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from '@mui/material';
import { colors } from 'shared/constants/theme';
import { useDebounce } from 'shared/hooks/useDebounce';

export const AllInvoicesTable = () => {
  const [dates, setDates] = useState<any>([]);
  const [search, setSearch] = useState<string>('');
  const [invoices, setInvoices] = useState<IFormInput[] | null>(null);
  const [dateState, setDateState] = useState<any>([]);
  const [filterDate, setFilterDate] = useState<any>([]);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);

  const { customerId } = useParams();

  const { showSnackbar } = useNotification();

  const debouncedSearch = useDebounce(search, 500);

  const handleCountItems = (event: number) => {
    setRowsPerPage(event);
    setCurrentPage(1);
  };

  const fetchInvoices = async () => {
    setInvoices(null);
    try {
      const response = await InvoicesService.getInvoices({
        pageNumber: currentPage,
        limit: rowsPerPage,
        dates,
        customerId,
        search: debouncedSearch,
      });

      const { invoices, totalPages } = response;
      setInvoices(invoices);
      setTotalPages(totalPages);
    } catch (err: any) {
      showSnackbar(err.error, 'error');
    }
  };

  const getInvoiceFilterData = async () => {
    try {
      const { dates } = await InvoicesService.getInvoicesFilterDate({
        customerId,
      });
      return setFilterDate(dates);
    } catch (err: any) {}
  };

  const handleDateChange = (event: any) => {
    const {
      target: { value },
    } = event;
    setDateState(typeof value === 'string' ? value.split(',') : value);
  };

  useEffect(() => {
    fetchInvoices();
  }, [debouncedSearch, rowsPerPage, currentPage, dates]);

  useEffect(() => {
    getInvoiceFilterData();
  }, []);

  return (
    <Box sx={{}}>
      <RedesignedTable
        items={invoices}
        search={search}
        tableName="Invoicing"
        sectionTitleColor={'#FFF'}
        actionsColor={colors.white}
        addButton={false}
        setSearch={setSearch}
        columns={columns}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
        searchBeforeActions
        currentPage={currentPage}
        rowsPerPage={rowsPerPage}
        actions={
          <FormControl sx={{ width: 210 }} size="medium">
            <Select
              sx={{
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'unset',
                  color: '#828282',
                },
                '& .MuiInputBase-input': {
                  border: 'unset',
                  color: '#828282',
                },
                bgcolor: colors.lightGrey,
                borderRadius: '10px',
                height: '40px',
              }}
              multiple
              input={<OutlinedInput label="Tag" />}
              renderValue={(selected: any) => {
                if (selected.length === 0) return 'Dates';
                return selected
                  .map((s: any) => moment(s).format('MM-YYYY'))
                  .join(', ');
              }}
              displayEmpty
              value={dateState}
              onChange={handleDateChange}
              onClose={() => setDates(dateState)}
            >
              {filterDate.map((date: any) => (
                <MenuItem
                  key={date}
                  value={date}
                  sx={{ bgcolor: 'transparent', width: '100%' }}
                >
                  <Checkbox checked={dateState.indexOf(date) > -1} />
                  <ListItemText primary={moment(date).format('MM-YYYY')} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        }
        setRowsPerPage={handleCountItems}
        rowColor={(row) => row.status === 'Unpaid' && '#EB4C42'}
        searchable
      />
    </Box>
  );
};
