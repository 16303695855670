import { Grid } from '@mui/material';
import { StatisticsCard } from 'components/Cards/StatisticsCard';
import {
  twoRowGridItem,
  threeRowGridItem,
} from 'pages/Customer/Operation/styles';
import { useMemo } from 'react';

type Props = {
  statistics: {
    title: string;
    value?: string | number | null;
    currency?: string;
  }[];
};
export const Statistics = ({ statistics }: Props) => {
  const gridStyle = useMemo(
    () => (statistics?.length === 2 ? twoRowGridItem : threeRowGridItem),
    [statistics],
  );

  return (
    <>
      <Grid container rowSpacing={2.5} columnSpacing={1.5}>
        {statistics?.map((statistic: any) => (
          <Grid key={statistic.title} {...gridStyle}>
            <StatisticsCard {...statistic} small colored />
          </Grid>
        ))}
      </Grid>
    </>
  );
};
