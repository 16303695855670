import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { CustomModal } from 'components/Modals/CustomModal';
import { DeleteModal } from 'components/Modals/DeleteModal';
import { BaseTable } from 'components/Tables/BaseTable';
import { IFormInput } from 'components/Forms/InvoiceForm/types';
import { getColumns } from './columns';
import { useNotification } from 'shared/hooks/useNotification';
import InvoicesService from 'shared/services/invoices.service';
import { InvoiceForm } from 'components/Forms/InvoiceForm';
import { RedesignedTable } from '../RedesignedTable';
import { useDebounce } from 'shared/hooks/useDebounce';

export const InvoicesTable = (props: any) => {
  const { admin = false } = props;
  const [search, setSearch] = useState<string>('');
  const [showModal, setShowModal] = useState<boolean>(false);
  const [invoices, setInvoices] = useState<IFormInput[] | null>(null);
  const [delInvoice, setDelInvoice] = useState<string>('');
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [invoiceData, setInvoiceData] = useState<IFormInput | null>(null);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);

  const { customerId } = useParams();

  const { showSnackbar } = useNotification();

  const debouncedSearch = useDebounce(search, 500);

  const handleDeleteInvoice = useCallback(async () => {
    if (delInvoice) {
      try {
        await InvoicesService.deleteInvoice(delInvoice);
        setDelInvoice('');
        fetchInvoices();
        showSnackbar('Successfully Deleted', 'success');
      } catch (err: any) {
        showSnackbar(err.error, 'error');
      }
    }
  }, [delInvoice]);

  const handleEdit = (invoice: IFormInput) => {
    setShowEditModal(true);
    setInvoiceData(invoice);
  };

  const handleCountItems = (event: number) => {
    setRowsPerPage(event);
    setCurrentPage(1);
  };

  const fetchInvoices = async () => {
    setInvoices(null);
    try {
      const response = await InvoicesService.getInvoices({
        pageNumber: currentPage,
        limit: rowsPerPage,
        customerId,
        search: debouncedSearch,
      });
      const { invoices, totalPages } = response;
      setInvoices(invoices);
      setTotalPages(totalPages);
    } catch (err: any) {
      showSnackbar(err.error, 'error');
    }
  };

  const columns = getColumns({
    showActions: admin,
    handleEdit,
    onRowDelete: setDelInvoice,
  });

  useEffect(() => {
    fetchInvoices();
  }, [debouncedSearch, rowsPerPage, currentPage]);

  return (
    <>
      <CustomModal open={showModal} onClose={() => setShowModal(false)}>
        <InvoiceForm setShowModal={setShowModal} onFinish={fetchInvoices} />
      </CustomModal>
      <Box sx={{ width: '100%' }}>
        <RedesignedTable
          tableName="Invoices"
          items={invoices}
          columns={columns}
          search={search}
          setSearch={setSearch}
          setShowModal={setShowModal}
          totalPages={totalPages}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={handleCountItems}
          rowColor={(row) => row.status === 'Unpaid' && '#EB4C42'}
          searchable={true}
        />
      </Box>
      <CustomModal open={showEditModal} onClose={() => setShowEditModal(false)}>
        <InvoiceForm
          setShowModal={setShowEditModal}
          editData={invoiceData}
          onFinish={fetchInvoices}
        />
      </CustomModal>
      <CustomModal open={!!delInvoice} onClose={() => setDelInvoice('')}>
        <Box className="container">
          <DeleteModal
            title="invoice"
            onClick={handleDeleteInvoice}
            onCancel={() => setDelInvoice('')}
          />
        </Box>
      </CustomModal>
    </>
  );
};
