import { Actions } from 'components/Tables/Actions';
import { ColumnsType } from 'components/Tables/BaseTable/types';

export const getColumns = ({ handleEdit, onRowDelete }: any): ColumnsType[] => [
  {
    id: 'actions',
    label: 'Actions',
    minWidth: 130,
    align: 'left',
    sortable: false,
    render: (row) => (
      <Actions row={row} handleEdit={handleEdit} onRowDelete={onRowDelete} />
    ),
  },
  {
    id: 'id',
    label: 'ID',
    minWidth: 100,
    align: 'left',
  },
  {
    id: 'email',
    label: 'Email',
    minWidth: 100,
    align: 'left',
  },
  {
    id: 'username',
    label: 'Username',
    minWidth: 100,
    align: 'left',
  },
];
