import moment from 'moment';
import { ColumnsType } from 'components/Tables/BaseTable/types';
import { Actions } from '../Actions';

export const getColumns = ({ handleEdit, onRowDelete }: any): ColumnsType[] => [
  {
    id: 'actions',
    label: 'Actions',
    minWidth: 130,
    align: 'left',
    sortable: false,
    render: (row) => (
      <Actions row={row} handleEdit={handleEdit} onRowDelete={onRowDelete} />
    ),
  },
  {
    id: 'type',
    label: 'Type',
    minWidth: 100,
    align: 'left',
  },
  {
    id: 'description',
    label: 'Description',
    minWidth: 100,
    align: 'left',
  },
  {
    id: 'date',
    label: 'Date',
    minWidth: 100,
    align: 'left',
    render: ({ date }) => moment.utc(date).format('YYYY-MM-DD HH:mm'),
  },
];
